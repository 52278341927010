import { Avatar, Card, Typography } from "antd"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"

import styles from "./index.module.scss"

const { Paragraph } = Typography

export default function FeedProfile({ className = "" }) {
  const { userInfo } = useSelector(profileState)

  return (
    userInfo && (
      <Card
        bodyStyle={{ padding: 22 }}
        className={`${className} custom-card f-14 border border-gray-300 rounded-lg overflow-hidden`}
        cover={
          <div
            className={`${styles.cover} bg-cover bg-center bg-no-repeat bg-gray-100`}
            style={{
              backgroundImage: `url(${
                userInfo?.top_cover || "/assets/onBoarding/profile_cover.jpg"
              })`,
            }}
          />
        }
      >
        <div className="flex flex-col">
          <div
            className={`${styles.avatarContainer} bg-center bg-cover flex items-center mb-4`}
          >
            <Avatar
              size={110}
              src={userInfo?.icon || "/assets/onBoarding/profile_avatar.jpg"}
              className={`${styles.avatar} bg-gray-100`}
            />
          </div>

          <h2 className="f-20 font-medium m-0">
            <Paragraph
              ellipsis={{ rows: 2, tooltip: false }}
              className="mb-0 text-gray-800 f-19"
            >
              <span>
                {userInfo?.first_name} {userInfo?.last_name}
              </span>
            </Paragraph>
          </h2>
          {userInfo?.headline && (
            <Paragraph
              ellipsis={{ rows: 2, tooltip: false }}
              className="mb-0 text-gray-500 f-15 flex-grow"
            >
              <span>{userInfo?.headline}</span>
            </Paragraph>
          )}
          {userInfo?.location && (
            <div className="text-gray-500 f-13">
              <span className="icon-ic_room_24px mr-1" />
              {userInfo?.location}
            </div>
          )}
        </div>
      </Card>
    )
  )
}
