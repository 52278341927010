import { Col, Row } from "antd"
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from "react"
import { Keyboard, Mousewheel } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"

import Slide1 from "./Slide1"
import Slide2 from "./Slide2"
import Slide3 from "./Slide3"
import Slide4 from "./Slide4"

const items = [
  { child: <Slide1 />, backgroundSize: "cover", backgroundPosition: "60% 80%" },
  { child: <Slide2 /> },
  { child: <Slide3 /> },
  { child: <Slide4 /> },
]

const mobileImageStyles = {
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
}

const imageStyles = {
  top: 40,
  maxWidth: 2500,
  backgroundSize: "contain",
  backgroundPosition: "bottom right",
  height: "calc(100dvh - 40px)",
  backgroundRepeat: "no-repeat",
}

const Slide = ({ item, isMobile = false, isActive = false } = {}) => {
  const childrenWithProps = Children.map(item.child, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { isActive, isMobile })
    }
    return child
  })

  return (
    <Row
      style={
        isMobile
          ? { backgroundColor: "#f2f2f2" }
          : { height: "calc(100dvh - 56px)" }
      }
      className="relative z-10 md:mt-0 mt-2 flex-grow-0"
    >
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 1 }}
        lg={{ span: 10, offset: 2 }}
        xl={{ span: 9, offset: 3 }}
        xxl={{ span: 7, offset: 5 }}
        style={
          isMobile
            ? { paddingBottom: "20px", paddingTop: !isActive ? "20px" : "" }
            : {}
        }
      >
        {childrenWithProps}
      </Col>
    </Row>
  )
}

const Index = () => {
  const [isMobile, setMobile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const handleOnSlideChange = ({ activeIndex = 0 } = {}) => {
    if (loading || activeIndex === null) {
      return
    }

    setLoading(true)
    setCurrentSlide(activeIndex)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  const checkMobile = () => {
    setMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      checkMobile()
    }
    window.addEventListener("resize", checkMobile)
    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      {items.map((item, index) => (
        <div
          key={index}
          className="md:block hidden fixed z-0 transition-for-all start-0 end-0 mx-auto w-screen"
          style={{
            ...imageStyles,
            backgroundPosition:
              item.backgroundPosition || imageStyles.backgroundPosition,
            backgroundSize: isMobile
              ? "cover"
              : item.backgroundSize || imageStyles.backgroundSize,
            opacity: currentSlide === index ? 1 : 0,
            backgroundImage: `url(/assets/home/${index + 1}.jpg)`,
          }}
        />
      ))}

      <Swiper
        allowSlideNext={!loading}
        allowSlidePrev={!loading}
        allowTouchMove={!loading}
        spaceBetween={0}
        freeMode={isMobile}
        slidesPerView={1}
        mousewheel={true}
        grabCursor={true}
        threshold={1}
        modules={[Keyboard, Mousewheel]}
        keyboard={true}
        direction="vertical"
        style={{ zIndex: 1, height: "calc(100dvh - 56px)" }}
        onSlideChange={handleOnSlideChange}
      >
        <div style={{ zIndex: 1, height: "calc(100dvh - 56px)" }}>
          {items.map((item, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div className="flex flex-col">
                <Slide
                  item={item}
                  isActive={index === currentSlide}
                  isMobile={isMobile}
                  style={{ backgroundColor: "#f2f2f2" }}
                />

                <div
                  className="md:hidden block z-0 transition-for-all start-0 end-0 mx-auto w-screen flex-grow"
                  style={{
                    ...mobileImageStyles,
                    height: "60dvh",
                    opacity: currentSlide === index ? 1 : 0,
                    backgroundImage: `url(/assets/home/m${index + 1}.jpg)`,
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  )
}

export default Index
