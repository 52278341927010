import { Spin } from "antd"
import PostCard from "components/Feed/Posts/PostCard"
import InfiniteScroll from "react-infinite-scroller"

export default function FeedListViewContents({
  setPage,
  onFetch,
  refreshData,
  fetchedData,
  hideRemoveBookmark,
  fetchPagination,
}) {
  const isLastPage =
    fetchedData?.meta?.current_page >= fetchedData?.meta?.last_page

  return (
    <div className="pb-5">
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() =>
          !isLastPage && setPage(fetchedData?.meta?.current_page + 1)
        }
        hasMore={!isLastPage}
        loader={
          <div className="py-8 text-center" key={0}>
            <Spin />
          </div>
        }
      >
        <>
          {fetchPagination?.length > 0 &&
            fetchPagination?.map(
              (item, index) =>
                item && (
                  <PostCard
                    className="mb-4"
                    key={index}
                    item={item}
                    onFetch={onFetch}
                    refreshData={refreshData}
                    hideRemoveBookmark={hideRemoveBookmark}
                  />
                )
            )}
        </>
      </InfiniteScroll>
    </div>
  )
}
