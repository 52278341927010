import { Button } from "antd"
import CommunityAddTopic from "components/Community/CommunityAddTopic"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { communityReduxState } from "redux/slices/communitySlice"
import apiService from "services/apiService"

export default function FeedTopics({ url, feed = false, communityId = "" }) {
  const [items, setItems] = useState([])
  const [showMore, setShowMore] = useState(false)
  const { userPolicies } = useSelector(communityReduxState)
  const addPostPermission =
    feed || userPolicies?.some(policy => ["create_post"].includes(policy))

  useEffect(() => {
    if (!url) {
      return
    }

    const fetchData = async () => {
      try {
        const { data } = await apiService.get(url)
        if (data?.data?.length) {
          setItems(Object.entries(data?.data[0]))
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [url])

  return (
    <>
      {items?.length !== 0 ? (
        <div className="mb-2">
          <div
            className="flex flex-wrap mt-2 overflow-auto"
            style={{ maxHeight: 200 }}
          >
            {items.map(
              (item, index) =>
                index < (showMore ? items.length : 10) && (
                  <Link
                    className="w-1/2 truncate pr-2 text-gray-500"
                    prefetch={false}
                    href={`/search/hashtag?val=${item[0]?.replace("#", "")}${
                      communityId ? `&communityId=${communityId}` : ""
                    }`}
                    key={`hashtags-${index}`}
                  >
                    {item[0]}
                  </Link>
                )
            )}
          </div>
          {items?.length > 10 && (
            <Button
              type="link"
              className="p-0 f-12 text-primary mt-1"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "-" : "+"} Show {showMore ? "less" : "more"}
            </Button>
          )}
        </div>
      ) : (
        <div className="text-gray-500 mb-4 pt-1">
          No topic/hashtag to display.
        </div>
      )}

      {addPostPermission && <CommunityAddTopic />}
    </>
  )
}
