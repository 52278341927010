import { useDispatch } from "react-redux"
import { toggleDialog } from "redux/slices/addPostSlice"

export default function CommunityAddTopic({ text = "New Topic" }) {
  const dispatch = useDispatch()

  const onAdd = () => {
    dispatch(toggleDialog(true))
  }

  return (
    <div className="text-primary cursor-pointer mb-2" onClick={onAdd}>
      <span className="f-12">+ {text}</span>
    </div>
  )
}
